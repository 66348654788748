import { createStore } from "redux";

const initialState = {
  triggerFunction: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TRIGGER_FUNCTION":
      return { ...state, triggerFunction: true };
    case "RESET_TRIGGER":
      return { ...state, triggerFunction: false };
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
