import React, { useEffect, useState } from "react";
import { Content, Row, Col, Box, Button, SimpleTable } from "adminlte-2-react";
import { useDispatch } from "react-redux";

const apiUrl = process.env.REACT_APP_API_URL;
const designerUrl = process.env.REACT_APP_DESIGNER_URL;

function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("loggedIn", "0");
    dispatch({ type: "TRIGGER_FUNCTION" });

    window.location.href = designerUrl + "/login";
  }, []);

  return (
    <Content title="Du wirst abgemeldet...">
      <Row></Row>
    </Content>
  );
}

export default Logout;
