import React, { useState, useEffect, Text } from "react";
import AdminLTE, { Sidebar } from "adminlte-2-react";
import Home from "./components/Home";
import Questions from "./components/Questions";
import $ from "jquery";
import logo from "./assets/logo.png"; // Tell Webpack this JS file uses this image
import ListOfWines from "./components/ListOfWines";
import Dashboard from "./components/Dashboard";
import axios from "axios";
import Login from "./components/Login";
import Logout from "./components/Logout";
//import Users from "./components/Users";
import { getData } from "./components/Communication";
import "./App.css";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

//allow session cookies to be sent with axios
axios.defaults.withCredentials = true;

const apiUrl = process.env.REACT_APP_API_URL;
const designerUrl = process.env.REACT_APP_DESIGNER_URL;

function App() {
  const dispatch = useDispatch();
  const triggerFunction = useSelector((state) => state.triggerFunction);
  const [items, setItems] = useState([]);
  const { Item, Header, UserPanel, Searchbar } = Sidebar;
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [appInformation, setAppInformation] = useState({});
  const [allowedChannels, setAllowedChannels] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);

  const [mandantModalIsOpen, setMandantModalIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (triggerFunction) {
      checkSession();
      dispatch({ type: "RESET_TRIGGER" });
    }
  }, [triggerFunction, dispatch]);

  React.useEffect(() => {
    getData(process.env.REACT_APP_API_URL + "/status").then((data) => {
      setAppInformation(data.data);
    });

    setAllowedChannels(JSON.parse(localStorage.getItem("allowedChannels")));
    setCurrentChannel(localStorage.getItem("currentChannel"));
    if (
      (isLoggedIn && localStorage.getItem("currentChannel") == undefined) ||
      (localStorage.getItem("currentChannel") == null && window.location.pathname != "login" && window.location.pathname != "/login")
    ) {
      setMandantModalIsOpen(true);
    }
  }, []);

  React.useEffect(() => {
    console.log(appInformation);
  }, [appInformation]);

  React.useEffect(() => {
    if (currentChannel != null && isLoggedIn) loadChannelBasedTopics(currentChannel);
  }, [currentChannel]);

  const setFavicon = (channel) => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    if (channel == "lvk") link.href = "https://www.ludwig-von-kapff.de/assets/favicon/lvk.ico";
    if (channel == "cow") link.href = "https://www.club-of-wine.de/assets/favicon/apple-touch-icon.png?v=3";
  };

  const loadChannelBasedTopics = async (channel) => {
    console.log("CHANNEL", channel);
    if (channel != null) {
      let data = await getData(process.env.REACT_APP_API_URL + "/designer/tenants?channel=" + channel);
      console.log("DATA", data);
      if (data != undefined) {
        if (data.status == 200 || data.status == 201) {
          console.log("TENANT ID", data.data[0].id);
          localStorage.setItem("tenantId", data.data[0].id);
          if (channel == "lvk") {
            $(".main-header > .logo").removeClass("logoCoW");
            $(".main-header > .logo").addClass("logoLvK");
            document.title = "LvK Wine.Finder";
          }
          if (channel == "cow") {
            $(".main-header > .logo").removeClass("logoLvK");
            $(".main-header > .logo").addClass("logoCoW");
            document.title = "CoW Wine.Finder";
          }
          setFavicon(channel);
          let currentPage = window.location.pathname;
          if (currentPage == "/dashboard" || currentPage == "/form") {
            dispatch({ type: "TRIGGER_FUNCTION" });
          }
        }
      }
    } else {
      alert("Kein Channel gesetzt");
    }
  };

  const setSelectedChannel = (val) => {
    localStorage.setItem("currentChannel", val);
    loadChannelBasedTopics(val);
    setMandantModalIsOpen(false);
  };

  const sidebar = [
    <button
      onClick={() => setMandantModalIsOpen(true)}
      style={{ marginBottom: 40, marginLeft: 10, width: "90%", borderRadius: 7, padding: 10, backgroundColor: "#F2EEE8" }}
      type="button"
    >
      Channel wechseln...
    </button>,
    <Item key="Dashboard" text="Dashboard" to="/dashboard" icon="fa-chart-bar" />,
    <Item key="questions" text="Fragen & Antworten" to="/form" icon="fa-list" />,
    <Item key="wines" text="Weine" to="/ListOfWines" icon="fa-wine-bottle" />,
    <Item key="Logout" text="Abmelden" to="/Logout" icon="fa-power-off" />,

    <div className="appInformation">
      {appInformation.version}
      <br />
      {appInformation.environment}
    </div>,
  ];
  const noSidebar = [];

  const channelMapper = (val) => {
    if (val == "lvk") return "Ludwig von Kapff";
    if (val == "cow") return "Club of Wine";
  };

  const checkSession = () => {
    if (localStorage.getItem("loggedIn") == "1") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  useEffect(() => {
    let currentPath = window.location.pathname.split("/")[1];
    console.log("isLoggedIn", isLoggedIn);
    if (isLoggedIn !== null) {
      if (!isLoggedIn && currentPath != "login") {
        window.location.pathname = "/login";
      }
      if (isLoggedIn && currentPath == "login") {
        window.location.pathname = "/dashboard";
      }
    }
    if (!isLoggedIn) {
      document.body.classList.add("sidebar-collapse");
      $(".sidebar-toggle").hide();
      $(".main-header > .logo").hide();
    } else {
      document.body.classList.remove("sidebar-collapse");
      $(".sidebar-toggle").show();
      $(".main-header > .logo").show();
    }
  }, [isLoggedIn]);

  return (
    <>
      {!isLoggedIn && (
        <AdminLTE
          title={[]}
          titleShort={["CoW", "WineFinder"]}
          sidebar={isLoggedIn == true ? sidebar : noSidebar}
          theme="black-light"
          homeTo="/login"
          browserTitle="CoW - WineFinder"
        >
          <Login path="/login" />
          <Login path="/" />
        </AdminLTE>
      )}
      {isLoggedIn && (
        <AdminLTE
          title={[]}
          titleShort={["CoW", "WineFinder"]}
          theme="black-light"
          sidebar={isLoggedIn == true ? sidebar : noSidebar}
          homeTo="/dashboard"
          browserTitle="CoW - WineFinder"
        >
          <Questions path="/form" />
          <ListOfWines path="/ListOfWines" />

          <Dashboard path="/dashboard" />
          <Logout path="/Logout" />
        </AdminLTE>
      )}
      <Modal
        isOpen={mandantModalIsOpen}
        onRequestClose={() => setMandantModalIsOpen(false)}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            width: "50%",
            height: "40%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        <h3>Mandant wechseln</h3>
        {allowedChannels != null &&
          allowedChannels.map((item) => {
            return (
              <div onClick={() => setSelectedChannel(item)} className="channelSelection">
                {channelMapper(item)}
              </div>
            );
          })}
      </Modal>
    </>
  );
}

export default App;
