import axios from "axios";

const debug = true;

async function routeByHttpCode(code, url = "") {
  if (code == "401") {
    localStorage.setItem("loggedIn", "0");
    if (debug) console.log("Automatic logout by 401");
    window.location.pathname = "/login";
  }
  if (code == "500") {
    if (debug) {
      console.log("Fehler 500");
      console.log("ERROR URL", url);
    }
  }
}

export async function getData(url) {
  try {
    const response = await axios.get(url);
    if (debug) console.log("THEN", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("ERROR", url, error);
    if (error.response) routeByHttpCode(error.response.status, url);
  }
}

export async function postData(url, data) {
  try {
    const response = await axios.post(url, data);
    if (debug) console.log("THEN", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("ERROR", url, error);
    if (error.response) routeByHttpCode(error.response.status, url);
  }
}

export async function putData(url, data) {
  try {
    const response = await axios.put(url, data);
    if (debug) console.log("PUT success", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("PUT error", url, error);
    if (error.response) routeByHttpCode(error.response.status, url);
  }
}

export async function deleteData(url) {
  try {
    const response = await axios.delete(url);
    if (debug) console.log("THEN", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("ERROR", url, error);
    if (error.response) routeByHttpCode(error.response.status, url);
  }
}
